<template>
  <v-row class="navigationSlider d-flex justify-space-around" no-gutters>
    <v-col sm="4" />
    <v-col sm="4">
      <v-slider
        class="sliderClass align-self-center flex-grow-0"
        hint="Scroll pages"
        :max="pages"
        discrete
        min="1"
        :step="1"
        v-model="scrollNum"
        thumb-label
        height="18px"
        color="grey darken-2"
        track-color="grey darken-2"
        @change="$emit('slide', scrollNum)"
      ></v-slider>
    </v-col>
    <v-col sm="4" />
  </v-row>
</template>
<script>
export default {
  name: "NavigationSlider",
  props: {
    pages: {
      default: 0,
    },
    page: {
      default: 1,
    },
  },
  data: function () {
    return {
      scrollNum: 0,
    };
  },
  watch: {
    page: function () {
      this.scrollNum = !this?.page ? 1 : this?.page;
    },
  },
  mounted() {
    this.scrollNum = this.page || 1;
  },
};
</script>

<style lang="scss" scoped>
.navigationSlider {
  position: absolute;
  z-index: 11;
  width: 90vw;
}
</style>
